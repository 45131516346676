import { Button, Card, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from '@/shared/hooks';
import { billingModel } from '@/entities/billing';
import {
  MarkAsPaidModal,
  useMarkAsPaidModal,
} from '@/features/markAsPaidModal';

import { BillingHistoryOrderData, useBillingHistory } from '../../hooks';
import { BillingHistoryCell } from '../billingHistoryCell/BillingHistoryCell';

import styles from './BillingHistory.module.scss';

const { Title } = Typography;

const selectOrdersLoadingState = billingModel.selectors.getLoadingStateSelector('orders');

export const BillingHistory = () => {
  const { formatMessage } = useIntl();

  const billingHistoryData = useBillingHistory();
  const ordersLoadingState = useAppSelector(selectOrdersLoadingState);
  const { orderID, visible, openModal, closeModal } = useMarkAsPaidModal();

  const columns: ColumnsType<BillingHistoryOrderData> = [
    {
      key: 'date',
      dataIndex: 'date',
      render: (text) => <BillingHistoryCell
        className={styles.firstCell}
        text={text}
        label={formatMessage({ id: 'billingHistory.label.date', defaultMessage: 'Date' })}
      />
    },
    {
      key: 'invoice',
      dataIndex: 'invoice',
      render: (text) => <BillingHistoryCell
        text={text}
        label={formatMessage({ id: 'billingHistory.label.invoice', defaultMessage: 'Invoice №' })}
      />
    },
    {
      key: 'subject',
      dataIndex: 'subject',
      render: (text) => <BillingHistoryCell
        withTooltip
        text={text}
        label={formatMessage({ id: 'billingHistory.label.subject', defaultMessage: 'Subject' })}
      />
    },
    {
      key: 'statusText',
      dataIndex: 'statusText',
      render: (text) => <BillingHistoryCell
        text={text}
        label={formatMessage({ id: 'billingHistory.label.status', defaultMessage: 'Status' })}
      />
    },
    {
      key: 'action',
      dataIndex: 'action',
      render: (_, record) => record.status === 'new' && (
        <Button type="link" onClick={() => openModal(record.id)}>
          <FormattedMessage id="billingHistory.markAsPaidButton" defaultMessage="Mark as paid"/>
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card
        title={
          <Title level={3} style={{ marginBottom: 0 }}>
            <FormattedMessage id="billingHistory.title" defaultMessage="Billing History" />
          </Title>
        }
        bordered={false}
        style={{ height: '100%' }}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingTop: 1 }}
      >
        <Table
          size="small"
          showHeader={false}
          columns={columns}
          dataSource={billingHistoryData}
          loading={ordersLoadingState !== 'succeeded'}
          scroll={{ x: true }}
          pagination={false}
        />
      </Card>

      <MarkAsPaidModal orderID={orderID} visible={visible} onCancel={closeModal} />
    </>
  );
};
