import cn from 'classnames';
import { Tooltip } from 'antd';

import styles from './BillingHistoryCell.module.scss';

type BillingHistoryCellProps = {
  className?: string;
  text: string;
  label: string;
  withTooltip?: boolean;
};

export const BillingHistoryCell = ({
  className,
  text,
  label,
  withTooltip = false,
}: BillingHistoryCellProps) => (
  <div className={cn(styles.container, className)}>
    <span className={styles.label}>{label}</span>

    {withTooltip ? (
      <Tooltip title={text} placement="topLeft">
        <span className={styles.text}>{text}</span>
      </Tooltip>
    ) : (
      <span className={styles.text}>{text}</span>
    )}
  </div>
);
